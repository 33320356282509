/*************************************************************
 Author     : Vivid Motion @ http://vividmotion.co/
 *************************************************************/

@import 'custom';

@media all and (min-width: 579px) {
  .team-member {
    flex-wrap: unset;
    justify-content: unset;

    text-align: left;
    &-thumb {
      margin-right: 1rem;
      margin-bottom: 0;
    }
    &-info {
      flex: 1;
      max-width: none;
    }
  }
}

@media all and (min-width: 768px) {
  .navbar-expand-md {
    padding: 4rem 0;
  }

  .navbar-scrolled .navbar-expand-md {
    padding: 0.5rem 0;
  }

  .menu {
    padding: calc(165px + 3rem) 0;
  }

  .navbar-nav {
    align-items: center;
    .nav-item {
      margin: 0 0 0 1.75rem;
    }
    .nav-item > .nav-link {
      padding: 0;
    }
  }

  $header-padding: 204px;

  #hero-contact,
  #hero-about {
    > .d-flex {
      padding-top: calc(3rem + #{$header-padding});
    }
  }

  #hero-home {
    > .d-flex {
      min-height: 80vh;
    }
    h1 {
      font-weight: 700;
      font-size: 3em;

      margin-bottom: 1rem;
    }
    h2 {
      font-size: 1.125em;
      font-family: $font-stack-primary;
      font-weight: 400;

      line-height: 1.6;
    }
    .btn {
      margin-top: 2rem;
    }
  }

  .btn {
    min-width: 10rem;
    &-lg {
      line-height: 3.5;
      min-width: 14rem;
    }
  }

  .menu-list {
    margin-bottom: 0;

    text-align: left;
    li a {
      font-size: 2.5em;
    }
  }

  .menu-service {
    & + & {
      margin-top: 1rem;
    }
  }

  .testimonial {
    p {
      font-size: 1.75em;
    }
  }

  .cta {
    min-height: 50vh;
  }

  #section-cta-1 {
    //padding-top: 6rem;
    padding-bottom: 24rem;
    h3 {
      font-size: 2.5em;

      line-height: 1.1;
    }
  }

  .section-team-member {
    &-info {
      h6 {
        font-weight: 700;
        font-size: 2em;
      }
      p {
        opacity: 0.6;

        font-size: 1.125em;

        margin-bottom: 0;
      }
    }
  }

  .cases-masonry {
    &-block-title {
      font-size: 3em;
    }
    &-item-half &-block-title {
      font-size: 2em;

      line-height: 1.25;
    }
    &-row-2 &-block-title {
      font-size: 1.5em;

      line-height: 1.35;
    }
  }

  .contact-cta {
    &-img {
      display: block;
    }
  }
}

@media all and (min-width: 1024px) {
  .hero,
  #hero-work,
  #hero-work-individual,
  #hero-contact,
  #hero-services,
  #hero-about,
  #hero-home {
    h1 {
      font-weight: 700;
      font-size: 4.5em;

      margin-bottom: 1rem;
    }
    h2 {
      font-size: 1.5em;
      font-family: $font-stack-primary;
      font-weight: 400;

      line-height: 1.6;
    }
    .btn {
      margin-top: 2rem;
    }
  }

  #hero-contact,
  #hero-services,
  #hero-about {
    > .d-flex {
      //min-height: 50vh;
    }
  }

  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .swiper-cases-wrapper {
    margin-bottom: 0;
  }

  .case-study {
    &-caption {
      padding: 4rem;
    }
    &-title {
      font-size: 3em;
    }
  }

  .featured-project {
    height: 100%;

    margin-top: 0;
  }

  #section-cta-1 {
    .cta-caption {
      background-color: $color-accent;
    }
  }

  .big-text {
    font-size: 1.25em;
  }

  .cases-masonry {
    margin-left: 0;
    margin-right: 0;
    &-col {
      max-width: 50%;
      flex: 0 0 50%;
    }
    &-block-thumb {
      padding-bottom: 100%;
    }
    &-item {
      max-width: 100%;
      flex: 0 0 100%;
    }
    &-item-half &-block-thumb {
      padding-bottom: 50%;
    }
    &-row-2 &-item {
      max-width: 50%;
      flex: 0 0 50%;
    }
    &-block-caption {
      font-size: 1em;
    }
  }

  .services-block {
    &:nth-child(even) &-caption {
      order: -1;
    }
    &-thumb {
      flex: 1;
    }
    &-caption {
      max-width: 40%;
    }
  }
}

@media all and (min-width: 1280px) {
  #section-cta-1 {
    h3 {
      font-size: 3em;
    }
  }
}

@media all and (min-width: 1440px) {

}

@media all and (min-width: 1650px) {

}

@media all and (max-width: 1199px) {

}

@media all and (max-width: 1024px) {

}

@media all and (max-width: 768px) {

}

@media all and (max-width: 767px) {
  h1 {
    font-size: 2.4em;
    line-height: 1;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.8em;
  }

  h4 {
    font-size: 1.6em;
  }

  h5 {
    font-size: 1.4em;
  }

  h6 {
    font-size: 1.2em;
  }

  .footer-title {
    margin-top: 2rem;
  }

  footer {
    text-align: center;
  }
}

@media all and (max-width: 576px) {

}

@media all and (max-width: 320px) {

}