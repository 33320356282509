/*************************************************************
 Author     : Vivid Motion @ http://vividmotion.co/
 *************************************************************/

@import 'custom';

body {
  font-family: $font-stack-primary;
  font-size: $font-size;
  background: $bg-body;
  color: $color-dark;
}

html, body {
  height: 100%;
}

h1 {
  font-size: 4.4em;
}

h2 {
  font-size: 3.6em;
}

h3 {
  font-size: 2.6em;
}

h4 {
  font-size: 2.2em;
}

h5 {
  font-size: 1.8em;
}

h6 {
  font-size: 1.4em;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;

  font-family: $font-stack-secondary;
}

p {
  line-height: $p-line-height;
}

a {
  color: $color-primary;
}

a:hover,
a:active {
  color: #A2A2A2;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

/*** Typography ***/

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-extrabold {
  font-weight: 900;
}

.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.clr-primary {
  color: $color-primary;
}

.clr-secondary {
  color: $color-secondary;
}

/*** Typography End ***/

/*** Header Start ***/

header {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  pointer-events: none;

  z-index: 1051;
}

.navbar-expand-md {
  pointer-events: none;

  background-color: transparent;

  border: 0;
  border-radius: 0;

  margin: 0;
  padding: 15px;

  transition: all .3s ease;
}

.navbar-nav {
  pointer-events: auto;
}

.navbar-scrolled .navbar-expand-md {
  background-color: white;
  border-bottom: 1px solid $gray-100;
  padding: 15px;
}

.navbar-expand-md.navbar-invert {
  background-color: white;
  border-bottom: 1px solid $gray-100;
  padding: 15px;
  .menu-toggler {
    > span {
      background-color: $color-dark;
    }
  }
  .navbar-brand {
    color: $color-dark;
  }
}

.navbar-brand {
  padding: 0;

  position: relative;
  img {
    height: 5rem;

    transition: opacity .7s ease;
  }
  img:last-child {
    opacity: 0;

    position: absolute;
    top: 0;
    left: 0;
  }
}

.navbar-scrolled .navbar-brand {
  img:first-child {
    opacity: 0;
  }
  img:last-child {
    opacity: 1;
  }
  img {
    max-height: 4rem;
  }
}

.menu-is-open .navbar-scrolled .navbar-brand {
  img:first-child {
    opacity: 1;
  }
  img:last-child {
    opacity: 0;
  }
}

.menu-is-open .navbar-scrolled .navbar-expand-md {
  background-color: $color-accent;
  border-bottom-color: rgba(white, 0.075);
}

.menu-is-open .navbar-scrolled .menu-toggler > span {
  background-color: white;
}

.navbar-invert .navbar-brand {
  img:first-child {
    opacity: 0;
  }
  img:last-child {
    opacity: 1;
  }
}

.navbar-nav {
  .nav-item > .nav-link {
    color: white;
    font-weight: 500;
  }
  .nav-item > .nav-link:hover {
    background-color: transparent;
  }
  .nav-item > .nav-link:focus {
    background-color: transparent;
  }
}

.navbar-scrolled .navbar-nav {
  .nav-item > .nav-link {
    color: $color-dark;
  }
}

.page-home {
  .menu-toggler > span {
    background-color: white;
  }
  .navbar-brand {
    color: white;
  }
  &.menu-is-open .menu-toggler > span {
    //background-color: $color-dark;
    background-color: white;
  }
  &.menu-is-open .navbar-brand {
    color: white;
  }
  &.menu-is-open .navbar-scrolled .menu-toggler > span {
    //background-color: $color-dark;
    background-color: white;
  }
  &.menu-is-open .navbar-scrolled .navbar-brand {
    color: $color-dark;
  }
  .navbar-scrolled .menu-toggler {
    > span {
      background-color: $color-dark;
    }
  }
  .navbar-scrolled .navbar-brand {
    color: $color-dark;
  }
}

.navbar-brand {
  font-family: $font-stack-secondary;
  font-size: 1em;
  font-weight: 700;

  pointer-events: auto;
}

/*** Header End ***/

/*** Hero Start ***/

.hero {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: black;
  > .d-flex {
    width: 100%;

    padding: 6rem 0;
  }
  > .d-flex > .d-flex {
    width: 100%;
  }
  h1 {
    font-weight: 700;
    font-size: 3em;

    line-height: 1.1;

    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1em;
    font-family: $font-stack-primary;
    font-weight: 400;

    line-height: 1.6;
  }
  .btn {
    margin-top: 1.5rem;
  }
}

#hero-home {
  overflow: hidden;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  //background: radial-gradient(circle farthest-side at 50% 50%, lighten(#202067, 10%), #202067);
  color: white;
  > .d-flex {
    position: relative;
    z-index: 2;
  }
  h1 {
    font-weight: 700;
    font-size: 2.5em;

    line-height: 1.2;

    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1em;
    font-family: $font-stack-primary;
    font-weight: 400;

    line-height: 1.6;
  }
  .btn {
    margin-top: 1.5rem;
  }
}

#hero-home + .section {
  z-index: 3;
}

#hero-home {
  overflow: hidden;
}

.video-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;

  transform: translate(-50%, -50%);

  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 105%;
    height: 105%;
    transform: translate(-50%, 0);
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(black, 1), rgba(black, 0) 25%);
    z-index: 2;
    opacity: 0.75;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

#hero-about {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  background-color: $color-primary;
  background-image: url('../img/bg-about.jpg');
  color: white;
  > .d-flex {
    position: relative;
    z-index: 2;
    padding-top: calc(3rem + #{$header-padding});
  }
  h1 {
    font-weight: 700;
    font-size: 2.5em;

    line-height: 1.1;

    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1em;
    font-family: $font-stack-primary;
    font-weight: 400;

    line-height: 1.6;
  }
  .btn {
    margin-top: 1.5rem;
  }
}

#hero-work {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  > .d-flex {
    position: relative;
    z-index: 2;

    padding: 8rem 0;
  }
  h1 {
    font-weight: 700;
    font-size: 2.5em;

    line-height: 1.1;

    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1em;
    font-family: $font-stack-primary;
    font-weight: 400;

    line-height: 1.6;
  }
  .btn {
    margin-top: 1.5rem;
  }
}

#hero-work-individual {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  > .d-flex {
    position: relative;
    z-index: 2;

    padding: 8rem 0;
  }
  h1 {
    font-weight: 700;
    font-size: 2.5em;

    line-height: 1.1;

    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1em;
    font-family: $font-stack-primary;
    font-weight: 400;

    line-height: 1.6;
  }
  .btn {
    margin-top: 1.5rem;
  }
}

#hero-contact {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  background-color: $color-primary;
  background-image: url('../img/bg-contact.jpg');
  color: white;
  > .d-flex {
    position: relative;
    z-index: 2;

    padding-top: calc(3rem + #{$header-padding});
  }
  h1 {
    font-weight: 700;
    font-size: 2.5em;

    line-height: 1.1;

    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1em;
    font-family: $font-stack-primary;
    font-weight: 400;

    line-height: 1.6;
  }
  a {
    color: white;
    text-decoration: underline;
  }
  a:hover {
    color: rgba(white, 0.75);
    text-decoration: underline;
  }
  .btn {
    margin-top: 1.5rem;
  }
}

.hero-subtitle {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.hero-title,
.hero-subtitle,
.hero-btn {
  opacity: 0;
  transform: translateY(-2rem);
  transition: opacity 700ms ease, transform 700ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hero-title.loaded,
.hero-subtitle.loaded,
.hero-btn.loaded {
  opacity: 1;
  transform: none;
}

/*** Hero End ***/

/*** Main Start ***/

.section {
  position: relative;
  padding: 6rem 0;
}

.case-study {
  position: relative;
  overflow: hidden;

  display: block;
  &-thumb {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;

    padding-bottom: 56.25%;

    transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &-caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: white;
  }
  &-title {
    font-weight: 700;
    font-size: 2em;

    line-height: 1.2;

    max-width: 40rem;
  }
  &-category {
    font-family: $font-stack-primary;
    font-weight: 400;
    font-size: 1em;

    position: relative;

    padding-left: 4rem;

    transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;

      width: 3rem;
      height: 1px;

      background-color: white;

      transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  &:hover &-thumb {
    transform: scale(1.1);
  }
  &:hover &-category {
    padding-left: 6rem;
    &:before {
      width: 5rem;
    }
  }
}

.swiper-cases-wrapper {
  margin-bottom: 3rem;

  position: relative;
  overflow: hidden;
}

.caption {
  h3 {
    font-size: 2em;
    font-weight: 700;

    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0;
  }
  p + * {
    margin-top: 2rem;
  }
}

.link {
  position: relative;

  display: inline-flex;
  align-items: center;

  transition: color .3s ease;
  &-title {
    font-weight: 700;
    margin-right: 0.5rem;
  }
  &:hover {
    color: $color-accent;
  }
  &:hover .arrow {
    transform: translateX(0.25rem);
  }
  &:hover .arrow-top {
    transform: rotate(40deg);
  }
  &:hover .arrow-bottom {
    transform: rotate(-40deg);
  }
}

.arrow {
  $size: 1.5rem;
  $stroke: 1px;

  position: relative;

  transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1);
  &-body {
    width: $size;
    height: $stroke;

    background-color: currentColor;

    border-radius: $stroke;
  }
  &-top {
    position: absolute;
    right: 0;
    top: 0;

    transform-origin: right center;

    transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1), background-color .3s ease;

    width: calc(#{$size} / 3);
    height: $stroke;

    background-color: currentColor;

    border-radius: $stroke;
  }
  &-bottom {
    position: absolute;
    right: 0;
    top: 0;

    transform-origin: right center;

    transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1), background-color .3s ease;

    width: calc(#{$size} / 3);
    height: $stroke;

    background-color: currentColor;

    border-radius: $stroke;
  }
}

.menu-toggler {
  position: relative;

  width: 1.75rem;
  height: 14px;

  padding: 0;

  border: 0;
  background-color: transparent;

  cursor: pointer;
  pointer-events: auto;
  span {
    width: 100%;
    height: 2px;

    border-radius: 2px;

    background-color: $color-dark;
    transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1);

    display: block;

    position: absolute;
    top: 0;
    left: 0;
  }
  span:nth-child(2) {
    top: 7px;
  }
  span:nth-child(3) {
    top: 14px;
  }
}

.scene {
  position: absolute;

  pointer-events: none;
}

.scene:nth-child(1) {
  top: 8vw;
  right: 16vw;

  width: 10vw;
  height: 10vw;

  fill: lighten($color-accent, 10%);

  z-index: 2;
}

.scene:nth-child(2) {
  top: 6vw;
  right: 16vw;

  width: 20vw;
  height: 20vw;

  fill: $color-accent;

  z-index: 1;
}

.section-heading {
  margin-bottom: 3rem;
  h2 {
    font-size: 3em;
    font-weight: 700;

    opacity: 0;
    transform: translateY(1rem);
    transition: opacity 300ms ease, transform 300ms ease;
  }
  h3 {
    font-size: 2.5em;
    font-weight: 700;
  }
  p {
    max-width: 45rem;
    margin-left: auto;
    margin-right: auto;

    font-size: 1.25em;

    opacity: 0;
    transform: translateY(1rem);
    transition: opacity 300ms ease, transform 300ms ease;
  }
}

.section-heading h2.loaded {
  opacity: 1;
  transform: none;
}

.section-heading p.loaded {
  opacity: 0.6;
  transform: none;
}

.decor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;
}

.cta {
  position: relative;

  padding: 3rem 0;
}

#section-cta-1 {
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url('../img/cta-1.jpg');

  //color: white;

  //padding-top: 6rem;
  padding-bottom: 6rem;
  padding-top: 0;
  h3 {
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1.2;

    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 0;
  }
  .link {
    margin-top: 3rem;

    color: white;
  }
  .link:hover {
    color: white;
  }
  .cta-caption {
    background-color: rgba($color-accent, 0.75);
    color: white;

    padding: 6rem 3rem;
  }
}

.what-we-do {
  position: relative;

  display: block;
  text-align: center;

  padding: 1.5rem 2rem;

  transition: color .2s ease, opacity 300ms ease;
  opacity: 0;

  color: $color-dark;
  &:hover h6 {
    color: $color-accent;
  }
  &:hover {
    color: $color-dark;
  }
  &-icon {
    margin-bottom: 1rem;
  }
  &-icon svg {
    width: 3rem;
    height: 3rem;

    vertical-align: unset;
  }
  &-content {
    h6 {
      margin-bottom: 0.5rem;
      font-size: 1.5em;
      font-weight: 700;
      font-family: $font-stack-primary;
    }
    p {
      font-size: 1em;
    }
  }
  &-no {
    width: 4rem;
    height: 4rem;
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 1rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: $color-accent;
  }
  &-clone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    background-color: white;
    box-shadow: 0 0 0 1px #eaeaea;

    border-radius: 0.5rem;

    z-index: 2;

    padding: 1.5rem 2rem;

    opacity: 0;
    transition: opacity 300ms ease;

    pointer-events: none;
  }
  &:hover &-clone {
    opacity: 1;
  }
}

.what-we-do.loaded {
  opacity: 1;
}

.row-what-we-do > div:nth-child(1) svg {
  color: #2985D7;
}

.row-what-we-do > div:nth-child(2) svg {
  color: #39E9BB;
}

.row-what-we-do > div:nth-child(3) svg {
  color: #FFA107;
}

.row-what-we-do > div:nth-child(4) svg {
  color: #FF5252;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &-item {
    width: calc(100% / 4);
    height: 130px;

    display: table;
  }
  &-cell {
    display: table-cell;
    vertical-align: middle;
  }
  img {
    max-height: 130px;

    filter: grayscale(100%);

    opacity: 0.4;

    transition: opacity .3s ease;
  }
  &-item:hover img {
    opacity: 0.8;
  }
}

.testimonial {
  p {
    font-size: 1.25em;
    font-weight: 400;

    line-height: 1.5;

    margin-bottom: 0;
  }
  p:before,
  p:after {
    position: absolute;

    user-select: none;
    pointer-events: none;
  }
  p:before {
    content: "\201C";
    margin-left: -0.4em;
  }
  p:after {
    content: "\201D";
  }
  cite {
    font-style: normal;
    font-size: 0.875em;

    margin-top: 3rem;

    display: inline-block;
    span {
      display: block;
    }
    span:nth-child(1) {
      font-weight: 500;
    }
    span:nth-child(2) {
      font-weight: 300;

      opacity: 0.4;
    }
  }
}

//.testimonial {
//  transform: translateY(4rem);
//  opacity: 0;
//  transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1), opacity .7s ease;
//}
//
//.testimonial.loaded {
//  transform: translateY(0);
//  opacity: 1;
//}

.swiper-testimonials-wrapper {
  .swiper-slide {
    opacity: 0!important;
  }
  .swiper-slide-active {
    opacity: 1!important;
  }
  .swiper-navigation {
    margin-top: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-navigation-count {
    font-size: 0.75em;

    margin: 0 1rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-image: none;

    width: unset;
    height: unset;

    top: unset;
    position: unset;

    background: unset;

    margin: unset;

    left: unset;
    right: unset;
  }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    //opacity: unset;
  }
  .swiper-button {
    position: relative;

    width: 1.5rem;
    height: 1.5rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: black;

    border-radius: 50%;
  }
  .swiper-button svg {
    width: 0.75rem;
    height: 0.75rem;
  }
  .swiper-button svg path {
    fill: currentColor;
  }
  .swiper-button:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    border-radius: inherit;

    box-shadow: -1px 0 0 black;

    transition: transform .4s ease;
  }
  .swiper-button-next .swiper-button:before {
    box-shadow: 1px 0 0 black;
  }
  .swiper-button:hover:before {
    transform: rotate(1turn);
  }
}

//.swiper-testimonials .swiper-navigation {
//  transform: translateY(4rem);
//  opacity: 0;
//  transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1), opacity .7s ease;
//}
//
//.swiper-testimonials .swiper-navigation.loaded {
//  transform: translateY(0);
//  opacity: 1;
//}

.action {
  display: inline-flex;
  align-items: center;

  font-size: 1.125em;
  &-title {
    font-weight: 400;
  }
  &-icon {
    position: relative;

    width: 2em;
    height: 2em;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: currentColor;

    border-radius: 50%;

    margin-left: 0.75rem;
  }
  &-icon svg {
    width: 1em;
    height: 1em;
  }
  &-icon svg path {
    fill: currentColor;
  }
  &-icon:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    border-radius: inherit;

    box-shadow: -1px 0 0 currentColor;

    transition: transform .4s ease;
  }
  &:hover &-icon:before {
    transform: rotate(1turn);
  }
}

.footer-cta {
  display: flex;
  flex-wrap: wrap;
  &-col {
    max-width: 100%;

    flex: 1 1 auto;
  }
  &-block {
    position: relative;
    overflow: hidden;

    background-color: lighten($color-dark, 2.5%);

    display: block;
  }
  &-block-thumb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transform: scale(1.1);
    opacity: 0;

    transition: opacity .5s ease, transform 2s ease;
  }
  &-block-caption {
    position: relative;

    padding-top: 7rem;
    padding-bottom: 7rem;

    padding-left: 15px;
    padding-right: 15px;

    max-width: 35rem;

    margin-left: auto;
    margin-right: auto;

    text-align: center;

    color: white;

    z-index: 2;
    h6 {
      font-weight: 700;
      font-size: 3em;

      margin-bottom: 1rem;
    }
    p {
      font-weight: 400;
      font-size: 1em;

      margin-bottom: 0;
    }
  }
  &-block:hover &-block-thumb {
    transform: scale(1);
    opacity: 1;
  }
  &-block:hover .action-icon:before {
    transform: rotate(1turn);
  }
  .action {
    margin-top: 2rem;

    font-size: 0.875em;

    &-icon {
      color: white;
    }
  }
}

.menu {
  position: fixed;

  width: 100%;

  //background-color: white;
  background-color: $color-accent;
  color: white;

  padding: calc(61px + 3rem) 0;

  transform: translate3d(0, -100%, 0);
  transition: transform .4s cubic-bezier(.555,.205,.295,.975);

  z-index: 1050;
}

.menu-open {
  transform: translate3d(0, 0, 0);
}

.menu-is-open {
  .menu-overlay {
    opacity: 1;
  }
  .menu-toggler > span:nth-child(1) {
    transform: translate3d(0, 7px, 0);
  }
  .menu-toggler > span:nth-child(3) {
    transform: translate3d(0, -7px, 0);
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1049;

  background-color: rgba(black, 0.1);

  opacity: 0;
  transition: opacity .4s ease;

  pointer-events: none;
  user-select: none;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;

  text-align: center;
  li + li {
    margin-top: 1.25rem;
  }
  li {
    transform: translate3d(0, 3rem, 0);
    opacity: 0;

    transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
  }
  li a {
    font-family: $font-stack-secondary;
    font-weight: 700;
    font-size: 1.5em;

    //color: $color-dark;
    color: white;

    transition: color .3s ease;
  }
  li a:hover {
    //color: $color-accent;
  }
}

.menu-service {
  padding: 1rem;
  margin: 0 -1rem;

  display: block;

  transform: translate3d(0, 3rem, 0);
  opacity: 0;

  //color: $color-dark;
  color: white;

  transition: opacity .7s cubic-bezier(0.19, 1, 0.22, 1), transform .7s cubic-bezier(0.19, 1, 0.22, 1), background-color .3s ease;
  & + & {
    margin-top: 0;
  }
  &:hover {
    //background-color: $gray-100;
    //color: $color-dark;
    background-color: darken($color-accent, 5%);
    color: white;
  }
  h6 {
    font-weight: 700;
    font-family: $font-stack-primary;
    font-size: 0.875em;

    text-transform: uppercase;

    margin-bottom: 1rem;
  }
  p {
    opacity: 0.6;

    margin-bottom: 0;
  }
}

.menu-list li.loaded {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.menu-service.loaded {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.featured-project.loaded .curtain {
  transform: scaleX(0);
}

.featured-project {
  height: calc(100vw * 900 / 1600);
  margin-top: 1.5rem;

  display: block;

  position: relative;
  overflow: hidden;
  .curtain {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: left center;

    background-color: $color-accent;

    z-index: 2;
  }
  &:hover &-thumb {
    transform: scale(1.1);
  }
  &-thumb {
    position: relative;

    height: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1);

    //padding-bottom: 100%;
  }
  &-caption {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    padding: 1.5rem 3rem;

    color: white;

    width: 75%;
    height: 8rem;

    display: flex;
    align-items: center;

    z-index: 2;
  }
  &-caption-bg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    width: 75%;
    height: 8rem;

    background-color: $color-accent;
    mix-blend-mode: darken;
  }
  &-title {
    font-size: 2.5em;
    font-weight: 700;

    position: relative;
    z-index: 2;
  }
  &-category {
    font-family: $font-stack-primary;
    font-weight: 400;
    font-size: 1em;

    position: relative;

    padding-left: 4rem;

    transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;

      width: 3rem;
      height: 1px;

      background-color: white;

      transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

#site-content {
  position: relative;
  overflow: hidden;
}

#site-scroll {
  position: relative;

  transition: transform .5s ease;
  will-change: transform;
}

.cases-masonry {
  display: flex;
  flex-wrap: wrap;

  margin-top: 6rem;

  margin-left: -15px;
  margin-right: -15px;
  &-col {
    max-width: 100%;
    flex: 0 0 100%;
  }
  &-row {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
  }
  &-item {
    max-width: 100%;
    flex: 0 0 100%;
  }
  &-item-half &-block-thumb {
    padding-bottom: 50%;
  }
  &-row-2 &-item {
    max-width: 100%;
    flex: 0 0 100%;
  }
  &-block {
    position: relative;
    overflow: hidden;

    display: block;

    color: white;
  }
  &-block-thumb {
    position: relative;

    padding-bottom: 50%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    transition: transform .7s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &-block-caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    color: white;

    padding: 2rem;

    font-size: 0.75em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-block-title {
    font-weight: 700;
    font-size: 2em;

    line-height: 1.25;

    max-width: 30rem;
  }
  &-block-category {
    font-family: $font-stack-primary;
    font-weight: 500;
    font-size: 0.75em;

    position: relative;

    padding-left: 4rem;

    transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;

      width: 3rem;
      height: 1px;

      background-color: white;

      transition: all .7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  &-block:hover &-block-thumb {
    transform: scale(1.1);
  }
  &-block:hover &-block-category {
    padding-left: 6rem;
    &:before {
      width: 5rem;
    }
  }
}

.cases-page {
  margin-top: 4rem;
  .cases-masonry-block-caption {
    padding: 3rem;
  }
  .cases-masonry-block-thumb:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0;

    background-color: rgba($color-primary, 0.75);

    pointer-events: none;

    transition: opacity .4s ease;
  }
  .cases-masonry-block:hover .cases-masonry-block-thumb:before {
    opacity: 1;
  }
  .cases-masonry-block-title {
    font-size: 2.5em;
  }
}

.home-about-text {
  h2 {
    font-weight: 700;
    font-size: 1.75em;

    line-height: 1.35;

    margin-bottom: 1rem;
  }
}

#section-home-about {
  .link {
    color: $color-secondary;
  }
  .link:hover {
    color: lighten($color-secondary, 10%);
  }
}

#section-benefits {
  background-color: #202067;
  .section-heading {
    color: white;
  }
  .swiper-cases-wrapper {
    margin-bottom: -6rem;
  }
}

.contact-cta {
  position: relative;

  background-color: #202067;
  color: white;
  .d-flex {
    width: 100%;

    padding: 3rem 0;
  }
  > .d-flex {
    min-height: 25rem;
  }
  &-text {
    h3 {
      font-weight: 700;

      margin-bottom: 1.5rem;
    }
    h5 {
      font-size: 1em;
      font-weight: 500;
      font-family: $font-stack-primary;

      line-height: $p-line-height;

      margin-bottom: 0.5rem;
    }
    p {
      color: rgba(white, 0.4);

      font-size: 0.875em;

      margin-bottom: 0;
    }
    .link {
      margin-top: 2rem;
    }
    a {
      color: inherit;
    }
    a:hover {
      color: white;
    }
  }
  &-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 29rem;
    width: calc(29rem * 1600 / 900);
    max-width: 50vw;

    display: none;

    position: absolute;
    right: 0;
    bottom: 0;

    margin-top: -4rem;
  }
}

.noise-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 2;

  overflow: hidden;
}

#noise {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ul-sub-services {
  list-style: none;
  padding: 0;
  margin: 0.25rem 0 0 0;
  li + li {
    margin-top: 1rem;
  }
  li span {
    font-weight: 700;

    display: block;
  }
}

.team-member {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 1.5rem 0;
  &-thumb {
    position: relative;

    border-radius: 50%;

    margin-bottom: 1rem;
    img {
      border-radius: inherit;

      max-width: 100%;
      max-height: 4rem;
    }
  }
  &-info {
    flex: 0 0 100%;
    max-width: 100%;
    h6 {
      font-weight: 700;
      font-size: 1.25em;

      line-height: 1.1;
    }
    p {
      opacity: 0.6;

      margin-bottom: 0;
    }
  }
}

.section-team-member {
  margin: 0.75rem 0;
  &-thumb {
    position: relative;
    overflow: hidden;

    margin-bottom: 1rem;
    img {
      max-width: 100%;
    }
  }
  &-info {
    h6 {
      font-weight: 700;
      font-size: 1.125em;
    }
    p {
      opacity: 0.6;

      font-size: 0.75em;

      margin-bottom: 0;
    }
  }
}

.big-text {
  font-size: 1.125em;
}

.section-indicator {
  $size: 6rem;
  position: absolute;
  top: 0;
  left: 50%;

  transform: translate(-50%, -50%);

  width: $size;
  height: $size;
  line-height: $size;

  border-radius: $size;

  background-color: $color-accent;
  color: white;

  font-family: $font-stack-secondary;
  font-weight: 700;
  font-size: calc(#{$size} / 2);

  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-primary {
  color: white;
}

#section-page-team {
  background-color: darken($color-primary, 10%);
  color: white;
}

.services-block {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  .curtain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #202067;
    z-index: 1;
    transition: transform 700ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: scaleX(1);
    transform-origin: left center;
    pointer-events: none;
  }
  &:nth-child(even) .curtain {
    transform-origin: right center;
  }
  & + & {
    margin-top: 3rem;
  }
  &-thumb {
    position: relative;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    flex: 0 0 100%;

    padding-bottom: 50%;
  }
  &-caption {
    display: flex;
    align-items: center;

    background-color: white;

    width: 100%;
    max-width: none;
    min-width: 320px;
    h3 {
      font-weight: 700;
      font-size: 2em;

      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 0;
    }
    p + p {
      margin-top: 0.75rem;
    }
  }
  .link {
    color: $color-accent;

    margin-top: 3rem;
  }
  &-caption-inner {
    padding: 3rem;

    opacity: 0;
    transition: opacity 700ms ease;
  }
}

.services-block.loaded .curtain {
  transform: scaleX(0);
}

.services-block.loaded .services-block-caption-inner {
  opacity: 1;
  transition-delay: 350ms;
}

.mockup-container {
  position: relative;
  overflow: hidden;

  border-radius: 6px;

  pointer-events: none;
  user-select: none;

  background-color: white;
  background-image: url("../img/top-bar.svg");
  background-size: 48px auto;
  background-repeat: no-repeat;
  background-position: left 15px top 15px;

  box-shadow: 0 24px 128px rgba(black, 0.10);

  padding: 35px 0 0;
}

.mockup-container img {
  max-width: 100%;
}

.meta {
  margin-top: 4rem;

  display: flex;
  flex-wrap: wrap;

  margin-left: -1rem;
  margin-right: -1rem;
  > div {
    padding: 1rem;
  }
  h6 {
    font-family: $font-stack-primary;
    font-weight: 700;
    font-size: 1em;

    margin-bottom: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ul li {
    display: inline-block;

    font-weight: 600;
    font-size: 0.75em;

    border: 1px solid rgba($color-dark, 0.2);
    border-radius: 3px;

    padding: 4px 12px;

    margin: 0 2px 4px 0;
  }
  ul li + li {
    margin-top: 4px;
  }
}

.project-client-quote {
  padding: 3rem;

  border: 1px solid rgba($color-dark, 0.1);
  border-radius: 4px;

  margin: 1.5rem 0;
  p {
    font-style: italic;
  }
}

.cite {
  display: flex;
  align-items: center;

  margin-top: 20px;

  font-weight: 600;
  font-size: 0.75em;

  text-transform: uppercase;
  > img {
    margin-right: 20px;
    border-radius: 50%;

    max-width: 2rem;
    max-height: 2rem;
  }
  > div {
    color: $color-gray;
  }
  > div > span {
    color: rgba($color-gray, 0.4);
  }
}

.project-section-heading {
  font-size: 1.75em;
  font-weight: 700;

  margin-bottom: 1rem;
  margin-top: 2rem;

  display: inline-block;

  position: relative;
}

.project-ul {
  margin: 1rem 0;
  li {
    text-transform: lowercase;
  }
  li + li {
    margin-top: 0.5rem;
  }
}

.swiper-preview-wrapper {
  position: relative;
  //overflow: hidden;

  margin: calc(-100px + 2rem) 0;
  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    display: inline-block;

    margin: 0;

    width: 48px;
    height: 48px;

    border-radius: 100%;

    background: none;

    background-color: $color-primary;
    opacity: 1;

    z-index: 101;
  }
  .swiper-button-prev {
    left: -96px;
  }
  .swiper-button-next {
    right: -96px;
  }
  .swiper-button-next:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;

    left: 13px;
    top: 16px;

    border-right: 2px solid white;
    border-top: 2px solid white;

    transform: rotate(45deg);
  }
  .swiper-button-prev:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;

    left: 18px;
    top: 16px;

    border-left: 2px solid white;
    border-top: 2px solid white;

    transform: rotate(-45deg);
  }
  .swiper-button-prev,
  .swiper-button-next {
    transition: transform .3s ease, opacity .3s ease;
  }
  .swiper-button-disabled {
    opacity: 0.25;
  }
}

.swiper-preview {
  position: relative;
  overflow: hidden;

  padding: 100px;
  .swiper-slide img {
    max-width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 64px rgba(black, 0.1);

    transition: transform .4s, box-shadow .4s;
  }
  .swiper-slide:not(.swiper-slide-active) img {
    transform: scale(0.90);
    box-shadow: none;
  }
}

.technologies {
  display: flex;
  flex-wrap: wrap;

  margin: -1rem;
  &-el {
    position: relative;

    padding: 1rem;

    height: 180px;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;
    user-select: none;
    img {
      max-width: 140px;
      max-height: 60px;
    }
  }
}

#section-project {
  font-size: 1.125em;

  padding-top: 4rem;
  p {
    margin-bottom: 0;
  }
  p + p {
    margin-top: 1rem;
  }
}

.cta-block {
  background: linear-gradient(to bottom right, $color-primary, darken($color-primary, 10%) 50%);
  color: white;

  width: 100%;

  padding: 5rem 3rem;

  margin-top: 4rem;

  border-radius: 0.5rem;
  h3 {
    font-size: 1em;
    font-family: $font-stack-primary;
    font-weight: 400;

    margin-bottom: 0.5rem;
  }
  h4 {
    font-weight: 700;
    font-size: 2em;
  }
}

label {
  font-size: 0.75em;
  font-weight: 700;

  margin-left: 1rem;
}

.input {
  position: relative;
  &.active:before {
    height: 100%;
  }
  &.typing:before {
    background-color: white;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 2px;

    pointer-events: none;

    background-color: rgba($color-dark, 0.05);
    //background-color: $color-primary;

    border-radius: 0.5rem;

    transition: height .7s cubic-bezier(0.23, 1, 0.32, 1), background-color .3s ease;
  }
}

.form-control {
  position: relative;
  z-index: 2;

  border: 0;
  background-color: transparent;

  box-shadow: none;

  height: 3rem;

  font-size: 1.125em;

  padding: 0.5rem 1rem;
  &:focus {
    box-shadow: none;
    background-color: transparent;
  }
  &::placeholder {
    color: rgba($color-dark, 0.4);
  }
}

textarea.form-control {
  resize: none;
}

.list-ul {
  padding: 0 0 0 0;
  margin: 0;
  list-style: none;
  li {
    position: relative;
    padding-left: 1.25rem;

    color: rgba($color-dark, 0.75);
  }
  li + li {
    margin-top: 0.5rem;
  }
  li:before {
    content: "";
    position: absolute;
    left: 4px;
    top: 10px;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    box-shadow: 0 0 0 2px white, 0 0 0 4px currentColor;
    border-radius: 50%;

    margin-top: -1px;
  }
}

.dr-yoney {
  display: flex;
  flex-flow: row wrap;

  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  &-thumb {
    width: 13rem;
    height: 13rem;

    border-radius: 50%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;

    margin-right: 2rem;
  }
  &-caption {
    flex: 1;
    h3 {
      font-size: 2em;
      font-weight: 700;
      color: $color-primary;

      margin-bottom: 1rem;
    }
    h4 {
      font-size: 1em;
      font-weight: 700;
      font-family: $font-stack-primary;

      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 0;
    }
    p + p {
      margin-top: 1rem;
    }
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 400ms ease;
}

.fade-in.loaded {
  opacity: 1;
}

.big-text p {
  opacity: 0;
  transform: translateY(-1rem);
  transition: opacity 400ms ease, transform 400ms ease;
}

.big-text p.loaded {
  opacity: 1;
  transform: none;
}

.plyr {
  border-radius: 0.5rem;
}

.plyr__control--overlaid {
  background: rgba($color-accent, 0.8);
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: $color-accent;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track,
.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba($color-accent, 0.5);
}

.plyr--full-ui input[type=range] {
  color: $color-accent;
}

.plyr__poster {
  background-size: cover;
}

.videos-grid {
  display: flex;
  flex-flow: row wrap;
  margin: -30px;
  &-item {
    padding: 30px;
    max-width: calc(100% / 2);
    flex: 0 0 calc(100% / 2);
  }
  &-block-header {
    margin-bottom: 1rem;
    h3 {
      font-weight: 700;
      font-size: 2em;
    }
  }
  &-block-content {
    margin-top: 1.5rem;
    p {
      margin-bottom: 0;
    }
  }
}

.section-paragraph {
  @include boxed(50rem);
  font-size: 1.25em;
}

.banner {
  position: relative;

  display: flex;
  flex-flow: row wrap;

  margin: 6rem 0;
  &:before {
    content: "";
    position: absolute;
    top: -3rem;
    left: -3rem;
    right: 40%;
    bottom: -3rem;
    background-color: #f9f9fb;
  }
  &-caption {
    font-size: 1.25em;
    text-align: left;
    padding: 4rem 4rem 4rem 2rem;
    p {
      margin-bottom: 0;
    }
    .btn {
      margin-top: 1.5rem;
    }
  }
  &-column {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
  }
  &-column:nth-child(1) {
    width: 40%;
  }
  &-column:nth-child(2) {
    width: 60%;
  }
  &-image {
    width: 100%;

    background-color: $color-dark;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    padding-bottom: 60%;
  }
}

/*** Main End ***/

img[data-bg="true"] {
  display: none;
}

/*** Buttons Start ***/

.btn {
  border: 0;

  font-weight: 700;
  font-size: 0.75em;
  text-transform: uppercase;

  padding: 0.5rem 1rem;

  line-height: 2.5;

  min-width: 6rem;

  border-radius: 0.2rem;
  &-lg {
    line-height: 3;
    min-width: 10rem;
  }
  > span {
    position: relative;
    overflow: hidden;

    display: inline-block;
    line-height: 1;

    top: 2px;

    letter-spacing: 0.25px;
  }
  > span > span {
    display: inline-block;
    margin: 0 1.5px;
  }
  > span > span:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;

    display: inline-block;

    transform: translateY(-100%);
  }
  &:hover > span > span {
    animation: btn-hover-text .7s cubic-bezier(0.77, 0, 0.175, 1);
  }
  &-primary,
  &-primary:focus {
    background-color: $color-primary;
    color: white;
  }
  &-primary:hover,
  &-primary:active:focus {
    background-color: darken($color-primary, 5%);
    color: white;
  }
  &-secondary,
  &-secondary:focus {
    background-color: $color-secondary;
    color: white;
  }
  &-secondary:hover,
  &-secondary:active:focus {
    background-color: darken($color-secondary, 5%);
    color: white;
  }
  &-accent,
  &-accent:focus {
    background-color: $color-accent;
    color: white;
  }
  &-accent:hover,
  &-accent:active:focus {
    background-color: darken($color-accent, 5%);
    color: white;
  }
  &-outline-white,
  &-outline-white:focus {
    background-color: transparent;
    border: 1px solid white;
    color: white;
  }
  &-outline-white:hover,
  &-outline-white:active:focus {
    background-color: transparent;
    border-color: white;
    color: white;
  }
}

@keyframes btn-hover-text {
  to {transform: translateY(100%)}
}

/*** Buttons End ***/

/*** Misc Start ***/

.mt-5 {
  margin-top: 5rem !important;
}

.container {
  width: 100%;

  max-width: 1440px;
}

.container-fluid {
  width: 100%;
}

.overflow-h {
  overflow: hidden;
}

.bg-white {
  background-color: white;
}

*:focus {
  outline: 0!important;
}

/*** Misc End ***/

/*** Footer Start ***/

footer {
  padding: 1rem 0;

  background-color: #151450;
  color: white;
  hr {
    margin: 3.5rem 0;

    border-color: rgba(white, 0.075);
  }
}

.footer-title {
  font-family: $font-stack-primary;
  font-size: 1.125em;
  font-weight: 500;

  margin-bottom: 2rem;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  li + li {
    margin-top: 0.25rem;
  }
  li a {
    color: white;

    opacity: 0.4;

    font-weight: 300;
    font-size: 0.875em;
  }
  li a:hover {
    opacity: 1;
  }
}

.footer-links-inline {
  list-style: none;
  padding: 0;
  margin: -0.5rem;

  display: inline-flex;
  li {
    padding: 0.5rem;
  }
  li a {
    color: white;
    opacity: 0.25;

    font-weight: 300;
    font-size: 0.875em;
  }
  li a:hover {
    opacity: 1;
  }
}

.footer-contact {
  line-height: 1.75;

  font-size: 0.875em;
  font-weight: 300;
  > span {
    font-weight: 500;
  }
  a {
    color: white;
  }
  a:hover {
    color: white;
    text-decoration: underline;
  }
}

.copyright {
  font-weight: 300;
  font-size: 0.875em;

  margin-bottom: 0;

  opacity: 0.25;
}

/*** Footer End ***/